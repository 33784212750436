import React from "react";
import Header from "../Header/Header";
import './Hero.css';
// @ts-ignore
import hero_image from "../../assets/hero_image.png";
// @ts-ignore
import hero_image_back from "../../assets/hero_image_back.png";
// @ts-ignore
import Heart from "../../assets/heart.png"
// @ts-ignore
import Calories from "../../assets/calories.png";
import {motion} from 'framer-motion';
import NumberCounter from 'number-counter';

const Hero = () => { 
  const transition = {type:'spring',duration:3}
 
  const mobile  = window.innerWidth <= 768 ? true : false;
  return (
  <div className="hero" id="home">
    <div className="blur hero-blur">
      
    </div>
    <div className="left-h">
      <Header/>
      {/* The best ad */}
      <div className="the-best-ad">
        <motion.div
          initial ={{left:mobile? "130px":"180px"}}
          whileInView={{left:'8px'}}
          transition={{...transition,type:'tweet'}}>
        </motion.div>
        <span>The best fitness club in the town</span>
      </div>
      {/* Hero Heading */}
      <div className="hero-text">
       <div><span className="stroke-text">Shape</span><span>Your</span></div>
       <div>
       <span>Ideal Body</span>
       </div> 
       <div ><span>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nesciunt, quaerat quasi deleniti expedita aut rem doloribus obcaecati maiores! Explicabo, asperiores.
       </span>
       </div>

      {/* figures */}
      <div className="figures">
        <div>
          <span>
            <NumberCounter end={140} start={100} dalay ='4' prefix="+"/>
          </span>
          <span>Expert Coaches</span>
          </div>
        <div>
          <span>
            <NumberCounter end={890} start={700} dalay ='4' prefix="+"/></span>
          <span>Members Joined</span>
          </div>
        <div>
          <span>
          <NumberCounter end={50} start={0} dalay ='4' prefix="+"/>
          </span>
          <span>Fitness Programs</span>
          </div>
      </div>
      <div className="hero-buttons">
        <button className="btn">Get Started</button>
        <button className="btn">Learn More</button>
      </div>

    </div> 

    </div>
    <div className="right-h">
      <button className="btn">Join Now</button>

     <motion.div className="heart-rate"
          initial ={{right:'1rem'}}
          whileInView={{right:'4rem'}}
          transition={{...transition}}>
       <img src={Heart} alt="" /> 
       <span>Heart Rate</span>
       <span>116bpm</span>
     </motion.div> 

     {/* hero images */}
     <img src={hero_image}
      alt="" className="hero_image"/>
     <motion.img 
      initial ={{right:'11rem'}}
      whileInView={{right:'20rem'}}
      transition={{...transition}}
      src={hero_image_back} alt="" className="hero_image_back"/>
    {/* claories */}
    <motion.div initial ={{right:'37rem'}}
     whileInView={{right:'28rem'}}
     transition={{...transition}}
     className="calories">
      <img src={Calories} alt="" />
      <div>
      <span>Calories Burned</span>
      <span>220kcal</span>
      </div>
    </motion.div>



    </div>
  </div>
  )
};

export default Hero;
 